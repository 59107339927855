import styled from "styled-components"
import { device } from "../styles/stylesConfig"

const Container = styled.div`
    width: 100%;
    min-height: 200px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    position: relative;
    margin: 0px 50px;
    z-index: 1;

`

const TitleDiv = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    align-self: start;
    margin-left: 50px;

    & > h1 {
        font-size: 40px;
        font-weight: 700;
        letter-spacing: 0.5px;
        margin: 0 0 50px;
        text-transform: uppercase;
        text-align: center;

        @media ${device.md} {
            font-size: 30px;
        }

        @media ${device.sm} {
            font-size: 26px;
        }

        @media ${device.xs} {
            font-size: 18px;
        }

    }

    & > p {
        font-size: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;

        & > span {
            font-weight: 600;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        @media ${device.md} {
            font-size: 22px;
            padding: 0px 20px;
        }

        @media ${device.sm} {
            font-size: 18px;
        }

        @media ${device.xs} {
            font-size: 14px;
        }


    }

    @media ${device.lg} {
        width: 100%;
        margin: 0px;
        margin-bottom: 100px;
    }

`

export const Tip = () => {

    return (

        <Container>

            <TitleDiv>

                <h1 className="utils__fade-in-fast">¿Cuales son tus objetivos?</h1>

                <p className="utils__fade-in-fast">Plan personalizado con tu<br />profesor - <span>100% asegurado</span></p>

            </TitleDiv>

        </Container>

    )
}
