import styled from "styled-components"
import { useLogEvent } from "../hooks/useLogEvent"
import { device } from "../styles/stylesConfig"

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10vh 0vw 0vh;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
   
`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    position: relative;

    h1 {
        min-width: fit-content;
        color: #000;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        font-size: 100px;
        text-align: left;
        position: relative;
        margin-left: 50px;
        z-index: 1;

        @media ${device.md} {
            font-size: 70px;
        }

        @media ${device.sm} {
            font-size: 40px;
        }

        @media ${device.xs} {
            margin-left: 30px;
            font-size: 30px;
        }
    }

    h1::before {
        content: '';
        position: absolute;
        top: -1040px;
        left: -1000px;
        width: 5000px;
        height: 1px;
        background-color: #CCCCCC;
        transform: rotate(-45deg);
        z-index: -1;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: end;
        position: relative;
    }

    p {
        margin-top: -10px;
        text-align: end;
        line-height: 25px;
        background-color: #46FF4E;
        padding: 10px 10px 10px 50px;

        @media ${device.md} {
            font-size: 14px;
        }

        @media ${device.sm} {
            font-size: 12px;
            margin-top: -10px;
            line-height: 20px;
            padding: 10px 10px 10px 30px;
        }

        @media ${device.xs} {
            font-size: 10px;
            line-height: 16px;
            padding: 10px 10px 10px 20px;
        }
    }

    h3 {
        min-width: fit-content;
        color: #000;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        font-size: 30px;
        text-align: left;
        position: relative;
        margin-left: 70px;
        z-index: 1;

        @media ${device.md} {
            font-size: 24px;
        }

        @media ${device.sm} {
            font-size: 18px;
            margin-left: 50px;
        }

        @media ${device.xs} {
            font-size: 16px;
            margin-left: 20px;
        }
     }      

`

const InfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    position: relative;
    margin-top: 70px;

    @media ${device.md} {
        flex-direction: column;
    }
`

const Card = styled.div`
    height: 300px;
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 20;
    position: relative;
    background-color: #46FF4E;
    padding: 50px 30px 30px;
    border-radius: 7px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);

    @media ${device.lg} {
        width: 150px;
        height: 250px;
    }

    @media ${device.md} {
        width: 90%;
        height: 140px;
        margin-bottom: 30px;
    }

    @media ${device.sm} {
        width: 90%;
        height: 120px;
        margin-bottom: 30px;
    }
`

const CardTitle = styled.h2`
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 16px;
    text-align: center;
    letter-spacing: 7px;
    font-weight: bolder;

    @media ${device.lg} {
        font-size: 13px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }
`

const CardDescription = styled.li`
    font-size: 16px;
    text-align: start;
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 0px;
    }

    @media ${device.lg} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }

`


const ButtonKnowMore = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    background-color: #333;
    color: #46FF4E;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 10px;
    cursor: pointer;
    transition: all .2s ease;
    margin-top: 50px;
    align-self: end;

    &:hover {
        box-shadow: 0px 4px 12px 0px  rgba(0, 0, 0, 0.2);
    }

    img {
        width: 40px;
        height: auto;
        margin-left: 20px;
    }

    @media ${device.md} {
        margin-top: 10px;
        align-self: center;
    }

    @media ${device.sm} {
        margin-top: 0px;
        font-size: 12px;
        
        img {
            width: 30px;
        }
    }
    
`

const ImageTitle = styled.img`
    width: 40vw;
    height: auto;
    z-index: 1;

    @media ${device.md} {
        width: 60vw;
    }

    @media ${device.sm} {
        width: 80vw;
    }
`


interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const Services = ({ setShowModal }: Props) => {

    const { handleLogEvent } = useLogEvent()

    return (

        <Container>

            <Target id="services" />

            <ContentContainer>

                <TitleContainer>

                    <ImageTitle
                        className="utils__fade-in-fast"
                        src={`${process.env.REACT_APP_CDN}/servicios.webp`}
                        alt="servicios"
                    />

                    <div className="utils__fade-in-fast">

                        <p>Tenemos<br />los<br />mejores<br />servicios<br />para vos.</p>

                        <h3>¿Qué<br />lograrás?</h3>

                    </div>

                </TitleContainer>

                <InfoContainer>

                    <Card className="utils__fade-in-fast">

                        <CardTitle>RUTINAS</CardTitle>

                        <CardDescription>Aprenderás ejercicios y rutinas para cada grupo muscular (niveles básico, medio y avanzado)</CardDescription>

                        <CardDescription>Escogerás cuáles son los ejercicios que más te gustan para cumplir tus objetivos.</CardDescription>

                    </Card>

                    <Card className="utils__fade-in-fast">

                        <CardTitle>ALIMENTACIÓN</CardTitle>

                        <CardDescription>Somatotipos definición</CardDescription>

                        <CardDescription>Alimentos (vegetales, carbohidratos, proteínas) ¿Qué aportan?</CardDescription>

                        <CardDescription>Qué comer antes, durante y después del ejercicio</CardDescription>

                        <CardDescription>Sugerencias de dietas</CardDescription>

                    </Card>

                    <Card className="utils__fade-in-fast">

                        <CardTitle>SUPLEMENTOS</CardTitle>

                        <CardDescription>Aprenderás qué suplemento tomar antes, durante y después de cada entrenamiento.</CardDescription>

                    </Card>

                </InfoContainer>

                <ButtonKnowMore className="utils__fade-in-fast" onClick={() => {
                    setShowModal(true);

                    handleLogEvent('Click_boton_saber_mas_servicios');
                }}
                >

                    Conocer más

                    <img
                        src={`${process.env.REACT_APP_CDN}/flecha-derecha.webp`}
                        alt='Flecha derecha'
                    />

                </ButtonKnowMore>

            </ContentContainer>

        </Container>

    )
}
