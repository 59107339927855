import { useEffect, useState } from "react"
import { About } from "../components/About"
import { ContactForm } from "../components/ContactForm"
import { Header } from "../components/Header"
import { Introduction } from "../components/Introduction"
import { Rights } from "../components/Rights"
import { Services } from "../components/Services"
import { Social } from "../components/Social"
import { AboutModal } from "../components/AboutModal"
import { ServicesModal } from "../components/ServicesModal"
import { LogoBackground } from "../components/LogoBackground"
import { scrollFadeIn, scrollFadeInFast } from "../effects/Observers"
import { Preloader } from "../components/Preloader"
import { Plans } from "../components/Plans"

export const HomeScreen = () => {

    const [showAboutModal, setShowAboutModal] = useState(false);

    const [showServicesModal, setShowServicesModal] = useState(false);

    const [showPreloader, setShowPreloader] = useState(true);

    const handleClick = () => {

        setTimeout(() => {
            setShowPreloader(false);
        }, 2200);


    };

    useEffect(() => {

        handleClick()

        scrollFadeIn()
        scrollFadeInFast()


    }, [])

    return (

        <div className='home__main-content'>

            {showPreloader && <Preloader />}

            <LogoBackground />

            <Header />

            <Introduction />

            <About setShowModal={setShowAboutModal} />

            <Plans />

            {showAboutModal && <AboutModal showModal={showAboutModal} setShowModal={setShowAboutModal} />}

            <Services setShowModal={setShowServicesModal} />

            {showServicesModal && <ServicesModal showModal={showServicesModal} setShowModal={setShowServicesModal} />}

            <ContactForm />

            <Social />

            <Rights />

        </div>

    )
}
