import styled, { css } from "styled-components"
import { device, deviceHorizontal } from "../styles/stylesConfig"
import { IntroductionServices } from "./IntroductionServices"

const Container = styled.div<{ img: string }>`
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
    
    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: cover;
    `}


    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
`

const InfoContainer = styled.div`
    text-transform: uppercase;
    z-index: 2;
    color: white;
    padding-bottom: 100px;

    img {
        width: auto;
        height: 100px;
        margin-right: 40px;

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.lg} {
            height: 60px;
        }

        @media ${device.md} {
            margin-right: 20px;
            height: 40px;
        }

        @media ${device.sm} {
            margin-right: 0px;
            margin-bottom: 30px;
            height: 60px;

        }
        
        @media ${device.xs} {
            height: 50px;
        }
    }

    display: flex;
    flex-direction: row;
    width: 70%;
    align-items: center;
    justify-content: center;

    @media ${device.sm} {
        flex-direction: column;
    }
        
`

const LineMiddle = styled.div`
    width: 1px;
    height: 200%;
    background: rgba(255, 255, 255, 0.5);
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(45deg);

    &:first-of-type {
        transform: rotate(-45deg);
    }

`

const LineCorner = styled.div`
    width: 100vh;
    height: 100vh;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 170vh;
    margin: auto;
    transform: rotate(45deg); 

     &:last-child {
        left: unset;
        right: 170vh;
    } 

    @media ${device.xl} {
        left: 160vh;

        &:last-child {
            right: 160vh;
        } 

        @media ${deviceHorizontal.xl} {
            left: 140vh;

            &:last-child {
                right: 140vh;
            } 
        }

        @media ${deviceHorizontal.lg} {
            left: 160vh;

            &:last-child {
                right: 160vh;
            } 
        }

    }

    @media ${device.lg} {
        left: 85vh;

        &:last-child {
            right: 85vh;
        } 

        @media ${deviceHorizontal.xxl} {
            left: 75vh;

            &:last-child {
                right: 75vh;
            } 
        }

        @media ${deviceHorizontal.xl} {
            left: 85vh;

            &:last-child {
                right: 85vh;
            } 
        }

        @media ${deviceHorizontal.lg} {
            left: 135vh;

            &:last-child {
                right: 135vh;
            } 
        }

        @media ${deviceHorizontal.md} {
            left: 135vh;

            &:last-child {
                right: 135vh;
            } 
        }

        @media ${deviceHorizontal.sm} {
            left: 210vh;

            &:last-child {
                right: 210vh;
            } 
        }

    }
    
    
    @media ${device.md} {
        left: 85vh;

        &:last-child {
            right: 85vh;
        } 

        @media ${deviceHorizontal.xxl} {
            left: 75vh;

            &:last-child {
                right: 75vh;
            } 
        }

        @media ${deviceHorizontal.lg} {
            left: 170vh;

            &:last-child {
                right: 170vh;
            } 
        }

    }

    @media ${device.sm} {
        left: 70vh;

        &:last-child {
            right: 70vh;
        } 

        @media ${deviceHorizontal.lg} {
            left: 60vh;

            &:last-child {
                right: 60vh;
            } 
        }

        @media ${deviceHorizontal.md} {
            left: 70vh;

            &:last-child {
                right: 70vh;
            } 
        }
    } 
`

export const Introduction = () => {

    return (

        <Container id="home" img={`${process.env.REACT_APP_CDN}/gym1.webp`}>

            <InfoContainer>

                <img
                    className="utils__fade-in"
                    src={`${process.env.REACT_APP_CDN}/no-limites1.png`}
                    alt="sin limites"
                />

                <img
                    className="utils__fade-in"
                    src={`${process.env.REACT_APP_CDN}/no-limites2.png`}
                    alt="sin limites"
                />

                <img
                    className="utils__fade-in"
                    src={`${process.env.REACT_APP_CDN}/no-limites3.png`}
                    alt="sin limites"
                />

                <LineMiddle />

                <LineMiddle />

                <LineCorner />

                <LineCorner />

            </InfoContainer>

            <IntroductionServices />

        </Container>

    )
}
