import styled from "styled-components"
import { device } from "../styles/stylesConfig"

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 30px 0px;
    background-color: black;
    z-index: 1;

    @media ${device.md} {
        flex-direction: column;
        margin: 0px 0px 0px 0px;
    }

`

const TitleDiv = styled.div`
    width: 75%;
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    & > h2 {
        color: #fff;
        font-size: 20px;
        text-transform: uppercase;
        margin: 0 0 0 50px;
        text-align: center;

        @media ${device.md} {
            margin: 0px;
            font-size: 16px;
        }

        @media ${device.sm} {
            font-size: 14px;
        }
        
        @media ${device.xs} {
            font-size: 12px;
        }
    }

    @media ${device.lg} {
        height: 180px;
    }

    @media ${device.md} {
        width: 100%;
        height: 150px;
    }
    
    @media ${device.sm} {
        height: 100px;
    }
`

const DescriptionDiv = styled.div`
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    background-color: #46FF4E;
    overflow: hidden;
    padding-left: 150px;

    &::before {
        content: '';
        position: absolute;
        top: -500px;
        left: -400px;
        margin: auto;
        width: 500px;
        height: 1000px;
        background-color: black;
        rotate: 45deg;

        @media ${device.md} {
            visibility: hidden;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: -290px;
        left: 0px;
        margin: auto;
        width: 30px;
        height: 1000px;
        background-color: white;
        rotate: 45deg;

        @media ${device.md} {
            visibility: hidden;
        }
    }

    @media ${device.lg} {
        height: 180px;
    }

    
    @media ${device.md} {
        height: 150px;
        padding-left: 0px;
    }

    @media ${device.sm} {
        height: 200px;
    }

    & > p {
        color: #000;
        font-size: 14px;
        padding: 0px 50px 0px 100px;
        line-height: 24px;

        @media ${device.md} {
            padding: 0px 50px 0px 50px;
        }

        @media ${device.sm} {
            font-size: 10px;
        }
    }

`

interface Props {
    title: string;
    description: string;
}

export const AboutModalDataItem = ({ title, description }: Props) => {

    return (

        <Container>

            <TitleDiv>

                <h2>{title}</h2>

            </TitleDiv>

            <DescriptionDiv>

                <p>{description}</p>

            </DescriptionDiv>

        </Container>

    )
}
