import { CSSProperties } from 'react';
import { css, styled } from 'styled-components';

const LogoContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
`

const Logo = styled.div<{ img: string }>`
    width: 80vw;
    height: 80vh;
    z-index: 0;

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: contain;
    `}
`

interface Props {
    styles?: CSSProperties;
}


export const LogoBackground = ({ styles }: Props) => {


    return (

        <LogoContainer style={{ ...styles }} >

            <Logo img={`${process.env.REACT_APP_CDN}/bull-white.png`} />

        </LogoContainer>

    )
}
