import styled, { css } from "styled-components"
import { useLogEvent } from "../hooks/useLogEvent"
import { device } from "../styles/stylesConfig"
import React from "react"

const Container = styled.div`
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 100px 0;
    background-size: cover;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 20px;

    span {
        position: absolute;
        left: 50px;
        top: 50%;
        bottom: 50%;
        margin: auto;
        min-height: fit-content;
        color: #000;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-size: 40px;
        text-align: center;

        @media ${device.sm} {
            left: 20px;
            font-size: 30px;
        }

        @media ${device.xs} {
            font-size: 24px;
        }

    }
`

const Info = styled.div`
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 2;
    position: relative;

    @media ${device.lg} {
        width: 100%;
    }
`

const HistoryContainer = styled.div`
    width: 50%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    z-index: 1;

     @media ${device.lg} {
        width: 80%;
    } 

    & > h3 {
        font-size: 14px;
        color: #000;
        text-align: left;
        font-weight: 400;
        margin-bottom: 30px;
        line-height: 20px;

        @media ${device.lg} {
            text-align: justify;
        } 
    
    }

    & > button {
        // Container
        padding: 1rem 3rem;
        background-color: #333;
        cursor: pointer;
        outline: none;
        margin: 2rem 0rem 1rem 0rem;
        z-index: 1;
        backdrop-filter: blur(10px);
        transition: all 0.3s ease-in-out;

        // Text
        color: #46FF4E;
        text-align: justify;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 3.2px;

         @media ${device.lg} {
            align-self: center;
        } 

        &:hover {
            box-shadow: 0px 4px 12px 0px rgba(0,0,0, 0.5);
        }
    }
`

const Image = styled.div<{ img: string }>`
    width: 50%;
    min-height: 100%;
    position: relative;
    margin-right: 5rem;
    overflow: hidden;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: cover;
    `}


    &:last-of-type {
        visibility: hidden;
        position: absolute;
    }
 
    @media ${device.lg} {
        width: 100%;
        height: 400px;
        min-height: auto;

        &:first-of-type {
            visibility: hidden;
            position: absolute;
        }

        &:last-of-type {
        visibility: visible;
        position: relative;
    }

}
`

const ImageTitle = styled.img`
    width: 40vw;
    height: auto;

    @media ${device.md} {
        width: 60vw;
    }

    @media ${device.sm} {
        width: 80vw;
    }
`

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const About = ({ setShowModal }: Props) => {

    const { handleLogEvent } = useLogEvent()

    return (

        <Container>

            <Target id="about" />

            <ContentContainer>

                <Info>

                    <Image className="utils__fade-in-fast" img={`${process.env.REACT_APP_CDN}/gym2.webp`} />

                    <HistoryContainer>

                        <TitleContainer className="utils__fade-in-fast">

                            <ImageTitle
                                src={`${process.env.REACT_APP_CDN}/sobre.webp`}
                                alt="sobre"
                            >

                            </ImageTitle>

                            <span>Quien soy</span>

                        </TitleContainer>

                        <h3 className="utils__fade-in-fast">¿Quién Soy?</h3>

                        <h3 className="utils__fade-in-fast">Personal Trainer destinado a cambiar tu físico,  tu mentalidad y hacer superar tus límites.</h3>

                        <h3 className="utils__fade-in-fast">Hacerte ser parte del  1 % es mi objetivo.</h3>

                        <h3 className="utils__fade-in-fast">¿Estás dispuesto a hacerlo?</h3>

                        <button className="utils__fade-in-fast" id="plans" onClick={() => {
                            setShowModal(true);

                            handleLogEvent('Click_boton_saber_mas_nosotros');
                        }}
                        >
                            SABER MÁS
                        </button>

                    </HistoryContainer>

                </Info>

            </ContentContainer>

        </Container>

    )
}
