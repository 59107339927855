import { CSSProperties } from 'react';
import { css, styled } from 'styled-components';

const LogoContainer = styled.div`

    @keyframes hide {
        0% {
            opacity: 1;
        }
        25% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: #000;
    animation: hide 1.5s ease 0.7s;
`

const Logo = styled.div<{ img: string }>`
    width: 50vw;
    height: 50vh;
    z-index: 0;

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: contain;
    `}
`

interface Props {
    styles?: CSSProperties;
}


export const Preloader = ({ styles }: Props) => {


    return (

        <LogoContainer style={{ ...styles }}>

            <Logo img={`${process.env.REACT_APP_CDN}/logo-white.png`} />

        </LogoContainer>

    )
}
