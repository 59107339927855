import styled from "styled-components";
import { device } from "../styles/stylesConfig";

const Hamburger = styled.label`
    width: 40px;
    height: fit-content;
    cursor: pointer;
    position: absolute;
    visibility: hidden;
    z-index: 2; 

    @media ${device.md} {
        visibility: visible;
        position: relative;
    }

    @media ${device.sm} {
        width: 30px; 
    }

    & > input {
        display: none;
    }

    & > span {
        display: block;
        position: relative;
        height: 3px;
        width: 100%;
        background: rgb(255, 255, 255);
        border-radius: 10px;
        opacity: 1;
        left: 0;
        margin-bottom: 8px;
        transition: 0.3s ease-in-out;

        &:last-of-type {
            margin-bottom: 0;
        }

        @media ${device.sm} {
            height: 2px;
        }
    }
    & > span {
        transform-origin: left center;
    }
    & > input:checked ~ span {
        background: white;
        top: 5px;
        left: 5px;
        margin-bottom: 28%;
    }
    & > input:checked ~ span:nth-of-type(1) {
        transform: rotate(45deg);
    }
    & > input:checked ~ span:nth-of-type(2) {
        opacity: 0;
    }
    & > input:checked ~ span:nth-of-type(3) {
        transform: rotate(-45deg);
    }

`

interface Props {
    showMenu: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>
}

export const MenuButton = ({ showMenu, setShowMenu }: Props) => {

    return (

        <Hamburger>
            <input type="checkbox" checked={showMenu} onChange={() => setShowMenu(!showMenu)} />
            <span></span>
            <span></span>
            <span></span>
        </Hamburger>

    )
}
