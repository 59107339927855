
export const useAnimations = () => {

    const handleClickGoHome = () => {
        const element = document.getElementById('home');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoCatalog = () => {
        const element = document.getElementById('catalog');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoQuotator = () => {
        const element = document.getElementById('quotator');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoSocial = () => {
        const element = document.getElementById('social');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }


    const handleClickGoHomeRefresh = () => {
        const element = document.getElementById('home');
        if (element) {
            element.scrollIntoView({ behavior: 'auto' });
        }
    }

    const handleClickGoServices = () => {
        const element = document.getElementById('services');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoAbout = () => {
        const element = document.getElementById('about');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoPlans = () => {
        const element = document.getElementById('plans');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoWork = () => {
        const element = document.getElementById('work');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoWorkHome = () => {
        const element = document.getElementById('workHome');
        if (element) {
            element.scrollIntoView({ behavior: 'auto' });
        }
    }

    const handleClickGoLastWorks = () => {
        const element = document.getElementById('works');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }


    return {
        handleClickGoHome,
        handleClickGoCatalog,
        handleClickGoQuotator,
        handleClickGoSocial,
        handleClickGoHomeRefresh,
        handleClickGoServices,
        handleClickGoAbout,
        handleClickGoWork,
        handleClickGoWorkHome,
        handleClickGoLastWorks,
        handleClickGoPlans
    }

}