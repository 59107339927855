import { CSSProperties, useState } from "react"
import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import { FormProps } from "../interfaces/ContactInterfaces"
import { Tip } from "./Tip"
import { showMixinToast } from "../utils/Alerts"
import axios from 'axios'
import { endpoints } from "../helpers/FirebaseHelper"


const Container = styled.div`
    width: 90%;
    padding: 10vh 0px;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        height: 5000px;
        width: 1px;
        background-color: #ccc;
        bottom: -150%;
        right: -40%;
        margin: auto;
        rotate: 45deg;
        z-index: 1;
    }

    @media ${device.lg} {
        width: 100%;
        flex-direction: column;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    max-width: 400px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

`

const TitleDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & > h1 {
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin: 0 0 30px;

        @media ${device.md} {
            font-size: 32px;
        }

        @media ${device.sm} {
            font-size: 26px;
        }

        @media ${device.xs} {
            font-size: 20px;
        }
    }

`

const FormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        & > hr {
            width: 30px;
            border: none;
        }

        @media ${device.md} {
            flex-direction: column;
            & > hr {
                height: 20px;
            }
        }
        
    }

    @media ${device.sm} {
        width: 80%;
    }
`

const Input = styled.input<{ incomplete: string }>`
    width: 100%;
    background: #EBEBEB;
    max-width: 374px;
    background-size: cover;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 15px 12px;
    border: 1px solid transparent;
    font-size: 16px;
    transition: all 0.5s ease-in-out;
    margin-bottom: 10px;

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }

    ${({ incomplete }) => incomplete === 'true' && css`
        border-color: #ff7071;
        background-color: #fff0f0;
        box-shadow: 0 0 0 1px #ff7071 inset;
    `}

    &:focus {
        outline: 0 none;
        border-color: #46FF4E;
        background: #e8f8f6;
        box-shadow: 0 0 0 1px #46FF4E inset;
    }
`

const TextArea = styled.textarea<{ incomplete: string }>`
    width: 100%;
    min-height: 100px;
    max-width: 374px;
    background: #EBEBEB;
    background-size: cover;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 15px 12px 50px 12px;
    border: 1px solid transparent;
    font-size: 16px;

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }

    ${({ incomplete }) => incomplete === 'true' && css`
        border-color: #ff7071;
        background-color: #fff0f0;
        box-shadow: 0 0 0 1px #ff7071 inset;
    `}

    &:focus {
        outline: 0 none;
        border-color: #46FF4E;
        background: #e8f8f6;
        box-shadow: 0 0 0 1px #46FF4E inset;
    }
`

const SendButton = styled.button`
    width: 50px;
    height: 50px;
    position: relative;
    border-radius: 2px;
    font-size: 22px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    bottom: 7px;
    right: 7px;
    position: absolute;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 2px;
        height: 100%;
        width: 0%;
        background: rgba(255, 255, 255, 0.1);
    }

    &:hover {
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
        background-color: #B8D279;

        &::before {
            animation: fillButton 0.5s ease-in-out forwards;
        }
    }

    & > img {
        width: 25px;
        height: auto;
    }

    @media ${device.sm} {
        width: 40px;
        height: 40px;
        right: 0px;

        & > img {
            width: 20px;
            height: auto;
        }

    }
`


export const ContactForm = () => {

    const { handleLogEvent } = useLogEvent();

    const [form, setForm] = useState<FormProps>({
        name: '',
        email: '',
        phone: '',
        message: '',
    })


    const [fieldIncomplete, setFieldIncomplete] = useState({
        name: false,
        email: false,
        phone: false,
        message: false,
    })

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            ...fieldIncomplete,
            name: form.name.trim() === '',
            email: form.email.trim() === '' || !form.email.trim().includes('@'),
            phone: form.phone.trim() === '',
            message: form.message.trim() === '',
        });

        if (form.name.trim() === '') {
            existError = true;
        }
        if (form.email.trim() === '') {
            existError = true;
        }
        if (form.phone.trim() === '') {
            existError = true;
        }
        if (form.message.trim() === '') {
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = async (e: any) => {

        e.preventDefault();

        if (!formValidations()) {

            setForm({
                name: '',
                email: '',
                phone: '',
                message: '',
            })

            setFieldIncomplete({
                name: false,
                email: false,
                phone: false,
                message: false,
            })


            await axios.post(
                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                from: form.email,
                to: process.env.REACT_APP_SEND_EMAIL_TO,
                name: form.name,
                phone: form.phone,
                text: form.message
            }).then(() => {

                handleLogEvent('SendEmail');

            }).catch((e) => {
                console.log(e);

            }).finally(() => {

                showMixinToast('Mensaje enviado con exito!', 'success');

            })

        }

    }

    const styleCompleted: CSSProperties = {
        outline: '0 none',
        borderColor: '#B8D279',
        background: '#e8f8f6',
        boxShadow: '0 0 0 1px #B8D279 inset'
    }

    return (

        <Container id="home">

            <Tip />

            <ContentContainer>

                <TitleDiv className="utils__fade-in-fast">

                    <h1>DEJE SU MENSAJE</h1>

                </TitleDiv>

                <FormContainer className="utils__fade-in-fast" >

                    <div>

                        <Input
                            type={"text"}
                            placeholder={'Nombre'.toUpperCase()}
                            value={form.name}
                            incomplete={fieldIncomplete.name.toString()}
                            onChange={(e: any) => {

                                setFieldIncomplete({ ...fieldIncomplete, name: false })
                                setForm({
                                    ...form,
                                    name: e.target.value
                                })
                            }}
                            style={form.name.trim() !== '' ? { ...styleCompleted } : {}}
                        />

                        <Input
                            type={"text"}
                            placeholder={'Email'.toUpperCase()}
                            value={form.email}
                            incomplete={fieldIncomplete.email.toString()}
                            onChange={(e: any) => {
                                setFieldIncomplete({ ...fieldIncomplete, email: false })
                                setForm({
                                    ...form,
                                    email: e.target.value
                                })
                            }}
                            style={form.email.trim() !== '' ? { ...styleCompleted } : {}}
                        />

                        <Input
                            type={"text"}
                            placeholder={'Teléfono'.toUpperCase()}
                            value={form.phone}
                            incomplete={fieldIncomplete.phone.toString()}
                            onChange={(e: any) => {
                                setFieldIncomplete({ ...fieldIncomplete, phone: false })
                                setForm({
                                    ...form,
                                    phone: e.target.value
                                })
                            }}
                            style={form.phone.trim() !== '' ? { ...styleCompleted } : {}}
                        />

                        <TextArea
                            placeholder={'Mensaje'.toUpperCase()}
                            value={form.message}
                            incomplete={fieldIncomplete.message.toString()}
                            onChange={(e: any) => {
                                setFieldIncomplete({ ...fieldIncomplete, message: false })
                                setForm({
                                    ...form,
                                    message: e.target.value
                                })
                            }}
                            style={form.message.trim() !== '' ? { ...styleCompleted } : {}}
                        />

                    </div>

                    <SendButton
                        onClick={(e) => handleFormConfirm(e)}
                    >
                        <img
                            src={`${process.env.REACT_APP_CDN}/send.webp`}
                            alt="enviar"
                        />
                    </SendButton>

                </FormContainer>

            </ContentContainer>


        </Container>

    )
}
