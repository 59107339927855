import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from "react-router-dom";
import { HomeScreen } from '../screens/HomeScreen';

export const AppRouter = () => {

    return (

        <Router>

            <div className='router-content'>

                <Switch>

                    <Route
                        exact
                        path='/'
                        component={HomeScreen}
                    />

                    <Redirect to='/' />

                </Switch>

            </div>

        </Router>

    )
}
