import { styled } from 'styled-components';
import { device } from '../styles/stylesConfig';
import { useAnimations } from '../effects/Effects';

const LogoContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: all 0.75s;
    padding: 0;
    cursor: pointer;
`

const LogoImage = styled.img`
    margin-top: 10px;
    width: 100px;
    height: auto;

    @media ${device.md} {
        width: 70px;
        height: auto;
    }

    @media ${device.xs} {
        width: 60px;
        height: auto;
    }
`

export const AppLogoComponent = () => {

    const {
        handleClickGoHome
    } = useAnimations()

    return (

        <LogoContainer
            onClick={() => {
                handleClickGoHome()
            }}
        >

            <LogoImage
                src={`${process.env.REACT_APP_CDN}/logo-white.png`}
                alt='logo'
            />

        </LogoContainer>

    )
}
