import React from "react";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import { AboutModalDataItem } from "./AboutModalDataItem";
import { LogoBackgroundModal } from "./LogoBackgroundModal";

const Container = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
`

const ContentContainer = styled.div`
    height: 100%;
    width: 100%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;


    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }


`

const InfoContainer = styled.div`
    width: 90vw;
    height: 70vh;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.50);
    padding: 30px 0px 10px 0px;
    overflow: scroll; 
    z-index: 3;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }

`

const Close = styled.img`
    width: 20px;
    height: 20px;
    position: fixed;
    top: 20px;
    right: 3rem;
    cursor: pointer;
    z-index: 2;
`

const TitleDiv = styled.div`
    padding: 20px 70px 0px;

    & > h1 {
        text-align: center;
        color: #000;
        font-size: 50px;
        font-weight: bolder;
        text-transform: uppercase;
        letter-spacing: 6.4px;
        padding-bottom: 5px;
        margin-bottom: 1rem;
        position: relative;

        span {
            color: #46FF4E;
        }

        @media ${device.lg} {
            padding: 20px 0px 0px;
            font-size: 32px;
        } 

        @media ${device.md} {
            padding: 0px 0px 0px;
        } 

        @media ${device.sm} {   
            font-size: 24px;
        } 

    }

    & > h2 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 30px;

        @media ${device.md} {
            text-align: justify;
        } 

        @media ${device.sm} {
            font-size: 12px;
        } 

        @media ${device.xs} {

        } 
    }

    @media ${device.md} {
        padding: 20px 50px 0px;
        margin-bottom: 50px;
    } 

`

const ObjectivesDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 50px 50px 50px;

    @media ${device.md} {
        padding: 50px 50px 50px 50px;
    } 
`

const ObjectivesTitle = styled.h2`
    font-size: 30px;
    text-transform: uppercase;

    @media ${device.lg} {
        font-size: 24px;
        margin-top: 20px;
    } 

    @media ${device.md} {
        text-align: center;
    } 

    @media ${device.lg} {
        font-size: 16px;
        margin-top: 0px;
    } 

    @media ${device.xs} {
        font-size: 14px;
    } 

`

const ObjectivesSubtitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
    text-decoration: underline;

    @media ${device.lg} {
        font-size: 16px;
    } 

    @media ${device.md} {
        text-align: center;
    } 

    @media ${device.sm} {
        font-size: 14px;
    } 

    @media ${device.xs} {
        font-size: 12px;
    } 

`

const ObjectivesDescription = styled.p`
    font-size: 18px;
    font-weight: 400;
    margin-top: 30px;

    @media ${device.lg} {
        font-size: 16px;
    } 

    @media ${device.md} {
        text-align: justify;
    } 

    @media ${device.sm} {
        font-size: 14px;
    } 

    @media ${device.xs} {
        font-size: 12px;
    } 
`

const ObjectivesPhrase = styled.h2`
    font-size: 40px;
    font-weight: bolder;
    margin-top: 50px;

    @media ${device.lg} {
        font-size: 32px;
    } 

    @media ${device.sm} {
        font-size: 20px;
    } 

    @media ${device.xs} {
        font-size: 14px;
    } 

    strong {
        color: #46FF4E;
    }
`


interface Props {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const AboutModal = ({ showModal, setShowModal }: Props) => {

    return (

        <Container >


            <button
                onClick={() => {
                    setShowModal(false)
                }}
            >

                <Close
                    className='aboutModal__close-image'
                    src={`${process.env.REACT_APP_CDN}/cerrar.webp`}
                    alt='logo'
                    id="cerrarModal"
                />

            </button>


            <ContentContainer>



                <InfoContainer>

                    <LogoBackgroundModal
                        styles={{
                            width: '90vw',
                            height: '70vh'
                        }}
                    />


                    <TitleDiv>

                        <h1><span>Esteban</span> Personal trainer</h1>

                        <h2>
                            Personal Trainer, con una carrera de 3 años como estudiante y profesor, militar e instructor de Tiro.
                            Y en esta etapa de acompañamiento físico y mental verás cumplir tus objetivos día a día ganando disciplina,
                            rompiendo límites y saliendo de nuestra zona de confort a la que muchas veces estamos acostumbrados.
                        </h2>

                    </TitleDiv>


                    <AboutModalDataItem
                        title="Introducción al Mundo Fitness"
                        description="Los beneficios del mundo fitness son extensos y variados, 
                        mejora la capacidad respiratoria, la fuerza, la flexibilidad coordinación, 
                        agilidad, equilibro velocidad y composición corporal."
                    />

                    <AboutModalDataItem
                        title="Alimentación"
                        description="La alimentación es un pilar básico para nuestra actividad física diaria, 
                        debido a que nos proporciona nutrientes necesarios para nuestro cuerpo, conservar o 
                        reestablecer nuestra salud."
                    />

                    <AboutModalDataItem
                        title="Descanso"
                        description="Cuando descansamos, nuestro cuerpo se recupera y reinicia: 
                        La memoria mejora, el sistema inmune se fortalece, la presión sanguínea baja, 
                        la inflamación en el cuerpo se reduce, la concentración y capacidad mental mejora 
                        y se recupera la energía."
                    />

                    <ObjectivesDiv>

                        <ObjectivesTitle>¿Cuáles son tus objetivos y cuáles los míos?</ObjectivesTitle>

                        <ObjectivesSubtitle>Tus objetivos los definís vos y yo logro concretarlos.</ObjectivesSubtitle>

                        <ObjectivesDescription>
                            Trabajando juntos mejorando la actividad física, la buena alimentación y el descanso.<br />
                            Saldremos de la zona de confort y superaremos esos límites físicos y mentales.
                        </ObjectivesDescription>

                        <ObjectivesPhrase>“NO <strong>EXISTEN LIMITES</strong>”</ObjectivesPhrase>

                    </ObjectivesDiv>

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
