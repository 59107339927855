import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"

const Container = styled.div`
    width: 100%;
    height: 400px;
    background: #46FF4E;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 70px;

    @media ${device.md} {
        height: 350px;
    }

    @media ${device.sm} {
        height: 300px;
    }
`
const BoxStyle = styled.div`
    width: 300px;
    height: 100%;
    position: relative;

    @media ${device.md} {
        visibility: hidden;
        position: absolute;
    }
    
`
const ContentContainer = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    position: relative;
    align-self: end;

    @media ${device.md} {
        justify-content: center;
        align-items: center;
        align-self: center;
    }
    
`

const DataContainer = styled.div`
    height: 400px;
    background-size: cover;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    position: relative;

    @media ${device.md} {
        height: 350px;
    }

    @media ${device.sm} {
        height: 300px;
    }

`

const TitleDiv = styled.div`
    margin-left: -20px;

    @media ${device.md} {
        margin-left: 0px;
    }
`

const ImageTitle = styled.img`
    width: 40vw;
    height: auto;

    @media ${device.md} {
        width: 60vw;
    }

    @media ${device.sm} {
        width: 80vw;
    }
`

const Description = styled.div`
    color: white;
    font-size: 20px;
    margin-top: 30px;
    margin-left: 10px;

    @media ${device.sm} {
        font-size: 18px;
    }

    @media ${device.xs} {
        font-size: 14px;
    }
`

const PricingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -10px;
    height: 100%;

    @media ${device.md} {
        margin-left: 0px;
        padding: 0 20px;
    }
`

const Price = styled.div`
    font-size: 120px;
    color: #000;
    font-weight: 500;

    @media ${device.md} {
        font-size: 90px;
    }

    @media ${device.sm} {
        font-size: 50px;
    }

    @media ${device.xs} {
        font-size: 40px;
    }
`

const PricingInfo = styled.div`
    margin-left: 20px;

    h2 {
        color: black;
        font-size: 14px;
        text-transform: uppercase;

        @media ${device.sm} {
            font-size: 12px;
        }

        @media ${device.xs} {
            font-size: 10px;
        }
    }

    hr {
        border: none;
        width: 25px;
        height: 1px;
        background-color: black;
        margin: 10px 0;
    }

    p {
        color: black;
        font-size: 14px;

        @media ${device.sm} {
            font-size: 12px;
        }

        @media ${device.xs} {
            font-size: 10px;
        }
    }

`

const Image = styled.div<{ img: string }>`
    height: 600px;
    width: 40%;
    margin: 0 50px -50px;
    z-index: 1000;

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: contain;
    `}

    @media ${device.lg} {
        visibility: hidden;
        position: absolute;
    }
`


const GreyBand = styled.div`
    height: 3000px;
    width: 500px;
    background-color: #EBEBEB;
    position: absolute;
    top: -425%;
    right: 0;
    transform: rotate(45deg);
    border: 70px solid white;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 160px;
        margin: auto;
        width: 1px;
        height: 50%;
        background-color: #CCCCCC;
    }

    @media ${device.md} {
        visibility: hidden;
    }
`


export const Plans = () => {

    return (

        <Container >

            <BoxStyle>

                <GreyBand />

            </BoxStyle>

            <ContentContainer>

                <DataContainer>

                    <TitleDiv className="utils__fade-in-fast">

                        <ImageTitle
                            src={`${process.env.REACT_APP_CDN}/planes.webp`}
                            alt="planes"
                        />

                        <Description>Elegí el plan que mas te guste.</Description>

                    </TitleDiv>

                    <PricingContainer className="utils__fade-in-fast">

                        <Price>$10.000</Price>

                        <PricingInfo>

                            <h2>Plan mensual</h2>

                            <hr />

                            <p>Entrena en cualquier momento del dia.</p>

                        </PricingInfo>

                    </PricingContainer>

                </DataContainer>

                <Image className="utils__fade-in-fast" img={`${process.env.REACT_APP_CDN}/gym3.webp`} />

            </ContentContainer>


        </Container>

    )
}
