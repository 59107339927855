import styled from "styled-components"
import { device } from "../styles/stylesConfig"

const Container = styled.div`
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 70px;
    margin: auto;

    @media ${device.xl} {
        width: 70%;
    }

    @media ${device.lg} {
        width: 80%;
    }

    @media ${device.md} {
        width: 100%;
        flex-direction: column;
        bottom: 20px;
    }
`

const InfoContainer = styled.div`
    padding: 15px 0px;
    width: 250px;
    background-color: rgba(0, 255, 42, 0.6);
    border-radius: 4px;

    & > h2 {
        color: white;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 2.7px;
        text-transform: uppercase;
        text-align: center;
    }

    @media ${device.lg} {
        width: 220px;

        & > h2 {
            font-size: 12px;

        }
    }

    @media ${device.md} {
        width: 400px;
        margin-bottom: 20px;
    }

    @media ${device.sm} {
        width: 300px;
        
        & > h2 {
            font-size: 10px;
        }
    }

    @media ${device.xs} {
        width: 250px;
        
        & > h2 {
            font-size: 8px;
        }
    }
`

export const IntroductionServices = () => {

    return (

        <Container className="utils__fade-in">

            <InfoContainer>

                <h2>Programas de ejercicios</h2>

            </InfoContainer>

            <InfoContainer>

                <h2>Ejecución de movimientos</h2>

            </InfoContainer>

            <InfoContainer>

                <h2>Programas de alimentación</h2>

            </InfoContainer>


        </Container>

    )
}
