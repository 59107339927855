import { useEffect, useState } from "react";
import { AppLogoComponent } from "./AppLogoComponent"
import { css, styled } from 'styled-components';
import { device } from "../styles/stylesConfig";
import { MenuButton } from "./MenuButton";
import { Menu } from "./Menu";
import { useAnimations } from "../effects/Effects";

const Container = styled.div<{ show: string }>`
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 3;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    background-color: transparent;
    padding: 7.3px 0px;
    transition: all 0.75s ease-in;

    @media ${device.md} {
        height: 70px;
    }

    ${({ show }) => show === 'true' && css`
        background: rgba(124, 124, 124, 0.5);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(2px);
    `}  

`

const MenuDiv = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const NavDiv = styled.div`
    width: 600px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: space-between;

    @media ${device.lg} {
        width: 550px;
    }
    
    @media ${device.md} {
        visibility: hidden;
        position: absolute;
    }

    & > button {
        color: white;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 1.4px;
        padding: 10px;
        border-top: solid 1px transparent;
        border-bottom: solid 1px transparent;
        cursor: pointer;
        
        &:hover {
            border-color: white;
        }

}
`

export const Header = () => {

    const [show, setShow] = useState(false);

    const [showMenu, setShowMenu] = useState(false);

    const transitionNavBar = () => {

        if (window.scrollY > 50) {
            setShow(true);
        } else {
            setShow(false);
        }

    }

    useEffect(() => {

        transitionNavBar()

        window.addEventListener('scroll', transitionNavBar);

        return () => window.removeEventListener('scroll', transitionNavBar);

    }, []);

    const {
        handleClickGoHome,
        handleClickGoServices,
        handleClickGoAbout,
        handleClickGoSocial,
        handleClickGoPlans
    } = useAnimations()



    return (

        <Container show={show.toString()}>

            <MenuDiv>

                <AppLogoComponent />

                <NavDiv>

                    <button onClick={async () => { handleClickGoHome() }}>Inicio</button>

                    <button onClick={async () => { handleClickGoAbout() }}>Quién soy</button>

                    <button onClick={async () => { handleClickGoPlans() }}>Planes</button>

                    <button onClick={async () => { handleClickGoServices() }}>Servicios</button>

                    <button onClick={async () => { handleClickGoSocial() }}>Contacto</button>

                </NavDiv>

                <MenuButton showMenu={showMenu} setShowMenu={setShowMenu} />

            </MenuDiv>

            <Menu showMenu={showMenu} setShowMenu={setShowMenu} />

        </Container>

    )
}
