import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"

const Container = styled.div`
    width: 100%;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 50px 0px;
    background-color: black;
    z-index: 1;

    @media ${device.md} {
        flex-direction: column;
        margin: 50px 0px 0px 0px;
    }
`

const TitleDiv = styled.div`
    width: 75%;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    & > h2 {
        color: #fff;
        font-size: 20px;
        text-transform: uppercase;
        margin: 0 0 0 50px;
        text-align: center;

        @media ${device.md} {
            margin: 0px;
            font-size: 16px;
        }
        
        @media ${device.xs} {
            font-size: 14px;
        }
    }

    @media ${device.md} {
        width: 100%;
        min-height: 160px;
    }
`

const SocialDiv = styled.div`
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    background-color: white;
    overflow: hidden;
    padding-left: 150px;

    &::before {
        content: '';
        position: absolute;
        top: -500px;
        left: -400px;
        margin: auto;
        width: 500px;
        height: 1000px;
        background-color: black;
        rotate: 45deg;

        @media ${device.md} {
            visibility: hidden;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: -290px;
        left: 0px;
        margin: auto;
        width: 30px;
        height: 1000px;
        background-color: white;
        rotate: 45deg;

        @media ${device.md} {
            visibility: hidden;
        }
    }

    
    @media ${device.md} {
        padding-left: 0px;
        width: 100%;
    }

`

const SocialButton = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 20px;
    border-radius: 100px;


    &:hover {
        transform: scale(0.9);
        background-color: #eeeeee;

    }

    & > img {
        height: 45px;
        width: 45px;
        margin-bottom: 10px;

        @media ${device.sm} {
            height: 35px;
            width: 35px;
        }

    }

    & > p {
        color: #000;
        font-size: 14px;
        text-transform: uppercase;

        @media ${device.sm} {
            font-size: 10px;
        }
    }
`
const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

export const Social = () => {

    const { handleLogEvent } = useLogEvent();

    return (

        <Container>

            <Target id="social" />

            <TitleDiv className="utils__fade-in-fast">

                <h2>ENTERATE DE MÁS EN MIS REDES</h2>

            </TitleDiv>

            <SocialDiv>

                <SocialButton
                    className="utils__fade-in-fast"
                    href="https://www.instagram.com/este_banpersonaltrainer/"
                    target='_blank'
                    rel="noreferrer"
                    onClick={() => handleLogEvent('click_boton_ig')}
                >

                    <img
                        src={`${process.env.REACT_APP_CDN}/instagram.webp`}
                        alt="instagram"
                    />

                    <p>Instagram</p>

                </SocialButton>

                <SocialButton
                    className="utils__fade-in-fast"
                    href="https://www.facebook.com/profile.php?id=61555812506070"
                    target='_blank'
                    rel="noreferrer"
                    onClick={() => handleLogEvent('click_boton_fb')}
                >

                    <img
                        src={`${process.env.REACT_APP_CDN}/facebook.webp`}
                        alt="facebook"
                    />

                    <p>Facebook</p>

                </SocialButton>

                <SocialButton
                    className="utils__fade-in-fast"
                    href="https://wa.me/+5492236710785?text=Hola Esteban! Quiero solicitarte un plan de entrenamiento."
                    target='_blank'
                    rel="noreferrer"
                    onClick={() => handleLogEvent('click_boton_ws')}
                >

                    <img
                        src={`${process.env.REACT_APP_CDN}/whatsapp.webp`}
                        alt="whatsapp"
                    />

                    <p>WhatsApp</p>

                </SocialButton>

            </SocialDiv>

        </Container>

    )
}
