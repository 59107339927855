import styled, { css } from "styled-components";
import { device } from "../styles/stylesConfig";
import { useAnimations } from "../effects/Effects";

const MenuContainer = styled.div<{ show: string }>`
    width: 100%;
    display: flex;
    color: white;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 1;
    visibility: hidden;

    @keyframes openModal {
        0% {
            height: 0vh;
            background: rgba(0,0,0,0);
        }
        100% {
            height: 100vh;
            background: rgba(0,0,0,0.9);
        }
    }

    @keyframes closeModal {
        0% {
            height: 100vh;
            background: rgba(0,0,0,0.9);
        }
        100% {
            height: 0vh;
            background: rgba(0,0,0,0);
        }
    }

    @keyframes closeText {
        0% {
            font-size: 28px;
            @media ${device.xs} {
                font-size: 20px;
            }
        }
        100% {
            font-size: 0px;
        }
    }


    @media ${device.md} {
        visibility: visible;
    }

    ${({ show }) => show === 'false' && css`

        & > div {
            font-size: 0px;
            animation: closeText 0.2s normal;
            visibility: hidden;
        }

        height: 0vh;
        animation: closeModal 0.3s normal;


    `}

    ${({ show }) => show === 'true' && css`

        height: 100vh;
        animation: openModal 0.3s normal;
        background: rgba(0,0,0,0.9);
        

    `}
`

const NavButton = styled.div`
    color: white;
    font-weight: 500;
    font-size: 28px;
    letter-spacing: 5px;
    padding: 15px 0px;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease;
    &:hover {
        color: #B8D279;
        background-color: rgba(255,255,255,0.1);
    }

    @media ${device.xs} {
        font-size: 20px;
    }

`

interface Props {
    showMenu: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Menu = ({ showMenu, setShowMenu }: Props) => {

    const {
        handleClickGoHome,
        handleClickGoServices,
        handleClickGoAbout,
        handleClickGoSocial,
        handleClickGoPlans
    } = useAnimations()

    return (

        <MenuContainer show={showMenu.toString()} >

            <NavButton
                onClick={async () => {
                    setShowMenu(false);
                    handleClickGoHome()
                }}
            >
                Inicio
            </NavButton>

            <NavButton
                onClick={async () => {
                    setShowMenu(false);
                    handleClickGoAbout()
                }}
            >
                Quién soy
            </NavButton>

            <NavButton
                onClick={async () => {
                    setShowMenu(false);
                    handleClickGoPlans()
                }}
            >
                Planes
            </NavButton>

            <NavButton
                onClick={async () => {
                    setShowMenu(false);
                    handleClickGoServices()
                }}
            >
                Servicios
            </NavButton>

            <NavButton
                onClick={async () => {
                    setShowMenu(false);
                    handleClickGoSocial()
                }}
            >
                Contacto
            </NavButton>

        </MenuContainer>

    )
}
