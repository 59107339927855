import React from "react";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import { LogoBackgroundModal } from "./LogoBackgroundModal";

const Container = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
`

const ContentContainer = styled.div`
    height: 100%;
    width: 100%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;


    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }


`

const InfoContainer = styled.div`
    max-width: 90vw;
    max-height: 60vh;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.50);
    padding: 70px 150px;
    overflow: scroll; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }

    @media ${device.lg} {
        padding: 70px 50px;
    } 

    @media ${device.md} {
        padding: 50px 0px;
    } 

    @media ${device.sm} {
    } 

    @media ${device.xs} {
    } 

`

const Close = styled.img`
    width: 20px;
    height: 20px;
    position: fixed;
    top: 30px;
    right: 5rem;
    cursor: pointer;
    z-index: 2;

    @media ${device.md} {
        right: 3rem;
    } 
`

const TitleDiv = styled.div`

    & > h1 {
        text-align: center;
        color: #000;
        font-size: 50px;
        font-weight: bolder;
        text-transform: uppercase;
        letter-spacing: 6.4px;
        position: relative;

        span {
            color: #46FF4E;
        }

        @media ${device.lg} {
            font-size: 32px;
        } 

        @media ${device.sm} {   
            font-size: 24px;
        } 

    }

    & > h2 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 30px;

        @media ${device.md} {
            text-align: justify;
        } 

        @media ${device.sm} {
            font-size: 12px;
        } 

    }

    @media ${device.md} {
        padding: 0px 50px;
    } 

`

const ObjectivesDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 50px 50px 50px;
    z-index: 1;
`

const ObjectivesDescription = styled.li`
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    margin-top: 30px;

    @media ${device.lg} {
        font-size: 16px;
    } 

    @media ${device.md} {
        text-align: justify;
    } 

    @media ${device.sm} {
        font-size: 14px;
    } 

    @media ${device.xs} {
        font-size: 12px;
    } 
`

const ObjectivesPhrase = styled.h2`
    font-size: 40px;
    font-weight: bolder;
    margin-top: 20px;
    z-index: 1;

    @media ${device.lg} {
        font-size: 32px;
    } 

    @media ${device.sm} {
        font-size: 20px;
    } 

    @media ${device.xs} {
        font-size: 14px;
    } 

    strong {
        color: #46FF4E;
    }
`


interface Props {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const ServicesModal = ({ showModal, setShowModal }: Props) => {

    return (

        <Container >

            <button
                onClick={() => {
                    setShowModal(false)
                }}
            >

                <Close
                    className='aboutModal__close-image'
                    src={`${process.env.REACT_APP_CDN}/cerrar.webp`}
                    alt='logo'
                    id="cerrarModal"
                />

            </button>

            <ContentContainer>

                <InfoContainer>

                    <LogoBackgroundModal
                        styles={{
                            zIndex: 0,
                            maxWidth: '90vw',
                            maxHeight: '50vh'
                        }}
                    />

                    <TitleDiv>

                        <h1>¿Qué incluye tu plan?</h1>

                    </TitleDiv>

                    <ObjectivesDiv>

                        <ObjectivesDescription>
                            12 meses para entrenamiento en gimnasio o en casa
                        </ObjectivesDescription>

                        <ObjectivesDescription>
                            Alimentación 100% personalizada y enfocada en tus objetivos
                        </ObjectivesDescription>

                        <ObjectivesDescription>
                            Suplementos que puedes tomar antes durante y después del entrenamiento
                        </ObjectivesDescription>

                        <ObjectivesDescription>
                            Tips y consejos para ejecutar los ejercicios.
                        </ObjectivesDescription>

                        <ObjectivesDescription>
                            Ejercicios guiados con videos
                        </ObjectivesDescription>


                    </ObjectivesDiv>

                    <ObjectivesPhrase>“NO <strong>EXISTEN LIMITES</strong>”</ObjectivesPhrase>

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
